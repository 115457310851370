<template>
  <div>
    <Card>
      <template #title>Perfil de Segurança</template>
      <template #content>
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="perfilSegurancaList"
          dataKey="id"
          :filters.sync="filtros"
          :globalFilterFields="['nome']"
          filterDisplay="menu"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} vínculos"
          responsiveLayout="scroll">
          <template #empty> Nenhum perfil de segurança encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <template #header>
            <span class="mt-2 mr-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              icon="pi pi-filter-slash"
              label="Limpar"
              class="mt-2 mr-2 p-button-outlined"
              @click="limparFiltro" />
            <Button
              label="Novo Perfil"
              icon="pi pi-plus"
              class="mt-2 mr-2 p-button-submit float-right"
              @click="showFormDialog()" />
          </template>
          <Column field="nome" header="Nome"></Column>
          <Column header="Ações">
            <template #body="{ data }">
              <Button
                v-tooltip.left="'Editar'"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="showFormDialog(data)" />
              <Button
                v-tooltip.left="'Remover'"
                class="p-button-danger p-button-rounded mt-2"
                icon="pi pi-trash"
                @click="removerPerfil(data)" />
            </template>
          </Column>
        </DataTable>
      </template>
      <template #footer> </template>
    </Card>
    <PerfiLSegurancaFormDialog
      :visible.sync="showDialog"
      :update="update"
      :propsperfilseguranca="perfilSeguranca" />
  </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import { watch } from 'vue-demi'
import PerfiLSegurancaFormDialog from './PerfiLSegurancaFormDialog.vue'
import PerfilSegurancaService from '@/service/PerfilSegurancaService'

export default {
  components: {
    PerfiLSegurancaFormDialog,
  },
  data() {
    return {
      loading: false,
      perfilSegurancaList: [],
      perfilSeguranca: {},
      filtros: {},
      showDialog: false,
      update: false,
    }
  },

  created() {
    this.perfilSegurancaService = new PerfilSegurancaService(this.$http)
    this.initFiltros()
    this.loading = true
    watch(
      () => this.showDialog,
      () => {
        if (!this.showDialog) {
          this.carregarDados()
        }
      },
    )
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    carregarDados() {
      this.perfilSegurancaService
        .getAll()
        .then((res) => {
          this.perfilSegurancaList = res
          this.loading = false
        })
        .catch((err) => {
          this.exibirToastErro(err)
        })
    },

    showFormDialog(data) {
      if (data) {
        this.perfilSeguranca = data
        this.update = true
      } else {
        this.perfilSeguranca = {}
        this.update = false
      }
      this.showDialog = true
    },

    removerPerfil(data) {
      this.perfilSegurancaService
        .delete(data.id)
        .then(() => {
          this.carregarDados()
          this.exibirToastSuccess('Perfil Segurança removido com sucesso!')
        })
        .catch((err) => {
          this.exibirToastErro(err)
        })
    },

    exibirToastSuccess(message) {
      this.$toast.add({
        severity: 'success',
        summary: message,
        life: 10000,
      })
    },

    exibirToastErro(err) {
      this.$toast.add({
        severity: 'error',
        summary: err.response,
        life: 10000,
      })
    },
  },
}
</script>
<style></style>
