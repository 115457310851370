<template>
  <Dialog
    :header="exibirHeader()"
    :visible="visible"
    :update="update"
    :contentStyle="{ overflow: 'visible' }"
    :containerStyle="{ width: '70vw' }"
    :modal="true"
    @update:visible="fecharDialog()"
    @show="onShow()"
    @hide="onHide()">
    <BlockUI :blocked="saving">
      <Card>
        <template #content>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-6">
              <label for="nome" class="required">Nome</label>
              <InputText
                id="nome"
                v-model="perfilSeguranca.nome"
                type="text"
                suffix="%" />
              <div
                v-if="submitted && v$.perfilSeguranca.nome.required.$invalid"
                class="p-error">
                O campo Nome é obrigatório.
              </div>
            </div>
            <div class="field col-6 md:col-6">
              <label for="status">Grupo Segurança</label>
              <MultiSelect
                v-model="gruposSegurancaSelecionados"
                :options="gruposSeguranca"
                optionLabel="nome"
                placeholder="Selecione o grupo" />
              <div
                v-if="
                  submitted && v$.gruposSegurancaSelecionados.required.$invalid
                "
                class="p-error">
                O campo Grupo Segurança é obrigatório.
              </div>
            </div>
            <div
              v-if="gruposSegurancaSelecionados.length > 0"
              class="field col-6 md:col-6">
              <label for="status">Roles</label>
              <MultiSelect
                v-model="rolesSelecionadas"
                :options="roles"
                optionLabel="nome"
                placeholder="Selecione a role"
                :disabled="blockDropRoles" />
              <div
                v-if="submitted && v$.rolesSelecionadas.required.$invalid"
                class="p-error">
                O campo Roles é obrigatório.
              </div>
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fecharDialog()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { watch } from 'vue-demi'
import PerfilSeguranca from '@/domain/PerfilSeguranca.js'
import PerfilSegurancaService from '@/service/PerfilSegurancaService'
import GrupoSegurancaService from '@/service/GrupoSegurancaService'
import RoleService from '@/service/RoleService'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsperfilseguranca: {
      type: Object,
      required: false,
      default: new PerfilSeguranca(),
    },
  },

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      perfilSeguranca: new PerfilSeguranca(),
      gruposSegurancaSelecionados: [],
      gruposSeguranca: [],
      rolesSelecionadas: [],
      roles: [],
      saving: false,
      submitted: false,
      blockDropRoles: true,
    }
  },

  validations() {
    return {
      perfilSeguranca: {
        nome: { required },
      },

      gruposSegurancaSelecionados: {
        required,
      },

      rolesSelecionadas: {
        required,
      },
    }
  },

  created() {
    this.perfilSegurancaService = new PerfilSegurancaService(this.$http)
    this.grupoSegurancaService = new GrupoSegurancaService(this.$http)
    this.roleService = new RoleService(this.$http)
    this.atualizarRoles()
    watch(
      () => this.gruposSegurancaSelecionados,
      () => {
        this.atualizarRoles()
      },
    )
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Perfil' : 'Novo Perfil'
    },

    fecharDialog() {
      this.perfilSeguranca = {}
      this.rolesSelecionadas = []
      this.gruposSegurancaSelecionados = []
      this.$emit('update:visible', false)
      this.$emit('update:update', false)
    },

    validate() {
      this.submitted = true
      this.v$.perfilSeguranca.$touch()

      if (
        !this.v$.perfilSeguranca.$invalid &&
        !this.v$.gruposSegurancaSelecionados.$invalid &&
        !this.v$.rolesSelecionadas.$invalid
      )
        return this.salvar()
    },

    salvar() {
      this.perfilSeguranca.grupos = this.gruposSegurancaSelecionados
      this.perfilSeguranca.roles = this.rolesSelecionadas
      this.saving = true
      this.perfilSegurancaService
        .salvarPerfil(this.perfilSeguranca)
        .then(() => {
          this.exibirToastSuccess('Perfil salvo com sucesso!')
          this.fecharDialog()
        })
        .catch((err) => {
          this.exibirToastError(err)
        })
    },

    onShow() {
      this.carregarGrupos()
      this.atualizarRoles()
      this.saving = false
      this.perfilSeguranca = JSON.parse(
        JSON.stringify(this.propsperfilseguranca),
      )

      if (this.update) {
        this.rolesSelecionadas = this.perfilSeguranca.roles
        this.gruposSegurancaSelecionados = this.perfilSeguranca.grupos
      }
    },

    onHide() {},

    carregarGrupos() {
      this.grupoSegurancaService
        .getAll()
        .then((res) => {
          this.gruposSeguranca = res
        })
        .catch((err) => {
          this.exibirToastError(err)
          this.fecharDialog()
        })
    },

    atualizarRoles() {
      this.roles = []
      if (this.gruposSegurancaSelecionados.length === 0) {
        this.blockDropRoles = true
      } else {
        this.blockDropRoles = false
        this.gruposSegurancaSelecionados.forEach((grupo) => {
          grupo.roles.forEach((role) => {
            if (!this.roles.includes(role)) {
              this.roles.push(role)
            }
          })
        })
        let array = this.rolesSelecionadas
        this.rolesSelecionadas.forEach((role) => {
          if (this.roles.filter((item) => item.id == role.id).length == 0) {
            array = array.filter((item) => item.id !== role.id)
          }
        })
        this.rolesSelecionadas = array
      }
    },

    exibirToastSuccess(message) {
      this.$toast.add({
        severity: 'success',
        summary: message,
        life: 10000,
      })
    },

    exibirToastError(err) {
      this.$toast.add({
        severity: 'error',
        summary: err.response.data.message,
        life: 10000,
      })
    },
  },
}
</script>
<style></style>
