export default class PerfilSegurancaService {
  constructor(http) {
    this._http = http
  }

  async getAll() {
    const { data } = await this._http.get('/api/perfis-seguranca/list')

    return data
  }

  async getOneById(id) {
    const { data } = await this._http.get(`/api/perfis-seguranca/${id}`)

    return data
  }

  async salvarPerfil(data) {
    if (data.id) {
      return await this._http.put('/api/perfis-seguranca', data)
    } else {
      return await this._http.post('/api/perfis-seguranca', data)
    }
  }

  async delete(id) {
    return await this._http.delete(`/api/perfis-seguranca/${id}`)
  }
}
